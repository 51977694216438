<template>
  <section>
    <Header />
    <div class="portalContainer">
      <div>
        <h2>Select An Option</h2>
      </div>
      <div class="selectionContainer">
        <div class="category__tile" @click="planningSelected">
          <a target="_blank">
            <h4>Assortment Planning Tool</h4>
          </a>
        </div>
        <div class="category__tile" @click="reportingSelected">
          <a target="_blank">
            <h4>Reporting & Dashboards</h4>
          </a>
        </div>
        <!-- <div class="category__tile">
          <a target="_blank" href="https://bauergamefit-staging.auth.us-east-2.amazoncognito.com/oauth2/idpresponse">
            <h4>Gamefit</h4>
          </a>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import Header from "@/components/common/Header";

export default {
  components: {
    Header,
  },
  methods: {
    planningSelected() {
      this.$router.push("/planning/setup");
    },
    reportingSelected() {
      this.$router.push("/reporting");
    }
  },

};
</script>
<style scoped lang="scss">
.category__tile {
  height: 300px;
  width: 500px;
  cursor: pointer;
  a {
    color: #fff;
    text-decoration: none;
    text-align: center;
    width: 100%;
    text-transform: capitalize;
  }
  h4 {
    text-align: center;
  }
}
.portalContainer {
  height: 100vh;
  h2 {
    text-align: center;
    margin-top: 20vh;
  }
}

.selectionContainer {
  width: 100%;
  margin-top: 15vh;
  column-gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
</style>
